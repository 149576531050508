<template>
    <div class="col pt-4 pl-4 overflow-auto scroll-none" style="height:calc(100vh - 81px);">
        <div class="row mx-0">
            <div class="col pl-0">
                <p class="f-28 text-general f-600">
                    {{ premiosLista[0].nombre_categoria }}
                </p>
                <div class="w-100" />
                <div class="like-bar mb-2" />
            </div>
        </div>
        <div class="row mx-0 mt-3">
            <card-premio v-for="data in premiosLista" :key="data.id" class="ml-0 mr-xl-3 mr-lg-3 mr-md-3 mr-sm-3 mr-2 mb-3" :info-premio="data" :tipo-pago="moneda" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        moneda: {
            type: Boolean,
            default: false
        },
        premiosLista: {
            type: Array,
            required: true,
        },
        
    },
    data(){
        return {
            value: ''
        }
    }
}
</script>
<style lang="scss" scoped>
@media (min-width: 300px) and (max-width: 1024px){
    .col.pt-4.pl-4{
        height: calc(100vh - 99px) !important;
    }
}
</style>